<template>
  <div>


    <div class="_pc-pad-y-60  _pc-pad-x-260  _mb-pad-y-60 _mb-pad-x-30  wow fadeInUp" >
      
      <div class="_flex-row-bet _pc-mar-bottom-45 _mb-mar-bottom-25">
        <div class="_flex-row">
          <div class="_pc-font-42 _mb-font-52 _b _pc-mar-right-20 title-color" style="line-height:0.9;">FREIGHT</div>
          <div class="_pc-font-28 _mb-font-36 _b" style="align-self:flex-end;">运费预估</div>
        </div>
        <div class="_pc-font-16 _grey-99 _mb-display-none" style="align-self:flex-end">O N E &nbsp;&nbsp; S T O P &nbsp;&nbsp; S E R V I C E</div>
      </div>
      <div class="_grey-99 _pc-font-16 _pc-mar-bottom-20  _mb-mar-bottom-50 _mb-font-24">根据不同的物品，我们会根据您的需求为您推荐合理的路线方案</div>
      


      <div class="_flex-col-mid">
        <div class="_flex-row-bet _mb-display-block _mb-w-max">
          <div class="_pc-mar-right-100 _pc-font-16 _mb-font-28 _mb-mar-right-0 _mb-mar-bottom-35">
            
            <div class="_flex-row-mid _pc-mar-bottom-25 _mb-display-block">
              <div @mouseleave="delaycloseSele" class="_flex-row-mid _mb-display-block _pc-mar-right-20 _mb-mar-bottom-30">
                <div class="_mb-mar-bottom-20">收货地区</div>
                <div   style="cursor:pointer;">
                  <div class="grey-bg _pc-w-320 _mb-w-max _mb-pad-x-20 _mb-pad-y-15 _pc-pad-x-20 _pc-pad-y-15  _pc-mar-left-15 _mb-mar-left-0 _flex-row-bet-mid _pos-rel" >
                    <!-- <div :class="country.id?'':'_grey-99'"> {{country.id?country.name:''}}</div> -->
                    <input @focus="isShow_sele = true" ref="inputs" class="grey-bg _w-max" type="text" v-model="input_keywords" placeholder="请选择收货地区">
                    <div class=" iconfont icon-zelvxuanzefeiyongdaosanjiaoxingfandui _pc-font-20 _grey-99 _mb-font-20"></div>
                  </div>
                  <div class="_pos-rel _pc-w-320 _mb-w-max _pc-mar-left-15" v-if="isShow_sele">
                    <div class="_pos-abs-top _w-max grey-bg _z-index-2 _max-h-200" style="border-radius: 0 0 10px 10px; overflow:auto;">
                      <!-- 遍历 -->
                      <div class="_pc-pad-x-20 _pc-pad-y-15 _mb-pad-x-20 _mb-pad-y-15 _line-1" style="cursor:pointer;" v-for="(item,index) in fuzzyQuery(country_list,input_keywords)" :key="index" @click.stop="changeSele(item)">{{item.name}}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="_flex-row-mid  _mb-mar-bottom-20 _mb-display-none">
                <div class="_pc-mar-right-15 _mb-mar-bottom-20">重量 (KG)</div>
                <input class="_pc-w-335 _mb-w-max _pc-pad-x-20 _pc-pad-y-15 _mb-pad-x-20 _mb-pad-y-15 grey-bg" type="text" placeholder="请输入重量" v-model="data.weight">
              </div>
            </div>

            <div class="_w-max _flex-row-bet-mid _mb-display-block">
              <div class="_mb-flex-row-mid _mb-mar-bottom-30">
                <div class="_flex-row-mid _mb-display-block  _flex-1 _pc-display-none _mb-mar-right-25">
                  <div class="_pc-mar-right-15 _mb-mar-bottom-20">重量 (KG)</div>
                  <input class="_pc-w-335 _mb-w-max _pc-pad-x-20 _pc-pad-y-15 _mb-pad-x-20 _mb-pad-y-15 grey-bg" type="text" placeholder="请输入重量" v-model="data.weight">
                </div>


                <div class="_flex-row-mid _mb-display-block _flex-1 ">
                  <div class="_pc-mar-right-15 _mb-mar-bottom-20">长（CM）</div>
                  <input class="_pc-w-180 grey-bg _mb-w-max _pc-pad-x-20 _pc-pad-y-15 _mb-pad-x-20 _mb-pad-y-15 _line-1" type="text" placeholder="请输入长度" v-model="data.length">
                </div>
              </div>

              <div class="_flex-row-mid ">
                <div class="_flex-row-mid _mb-display-block _flex-1 _mb-mar-right-25 _pc-mar-right-25">
                  <div class="_pc-mar-right-15 _mb-mar-bottom-20">宽（CM）</div>
                  <input class="_pc-w-180 _mb-w-max grey-bg _pc-pad-x-20  _pc-pad-y-15 _mb-pad-x-20 _mb-pad-y-15 _line-1" type="text" placeholder="请输入宽度" v-model="data.width">
                </div>

                <div class="_flex-row-mid _mb-display-block _flex-1">
                  <div class="_pc-mar-right-15 _mb-mar-bottom-20">高（CM）</div>
                  <input class="_pc-w-180 _mb-w-max grey-bg _pc-pad-x-20 _pc-pad-y-15 _mb-pad-x-20 _mb-pad-y-15 _line-1" type="text" placeholder="请输入高度" v-model="data.height">
                </div>
              </div>
            </div>

          </div>

          <div class="_flex-row-bet-mid _flex-1 _mb-display-none">
            <div class="_flex-col-mid _pc-font-14 _text-center"><img class="_pc-w-165 _pc-mar-bottom-15 _mb-mar-bottom-5" :src="account.image_input" alt=""> {{account.title}}</div>
            <div class="_border-right _pc-h-80 _pc-mar-top-25 _pc-mar-right-35 _pc-pad-left-35"></div>
            <div class="_flex-col-mid _pc-font-14 _text-center"><img class="_pc-w-165 _pc-mar-bottom-15 _mb-mar-bottom-5" :src="routine.image_input" alt=""> {{routine.title}}</div>
          </div>

        </div>

        <router-link class="_btn-hover _mb-w-max" to="" style="background:var(--theme-color-title); align-self:flex-start;" >
          <div class=" _pc-pad-x-90 _pc-pad-y-15 _mb-pad-y-20 _flex-row-mid _mb-flex-row-center-mid _white _pc-font-16 _mb-font-28" @click="save">
            立即查询
            <img class="_pc-w-20 _pc-mar-left-10 _mb-w-30 _mb-mar-left-10" src="@/assets/image/right-ico-03.png" alt="">
          </div>
        </router-link>
      </div>
      
      <div class="_pc-mar-top-60 _mb-mar-top-60 fadeInDown" v-if="route_data.list">
        <div class="_pc-font-28 _mb-font-32 _black _pc-mar-bottom-20 _mb-mar-bottom-30">共为您查询到{{route_data.list.length}}条线路方案</div>
        <div class="_flex-row-mid _mb-display-block _pc-mar-bottom-35 _mb-mar-bottom-30 _pc-font-16 _mb-font-24">
          <div class="_pc-mar-right-40 _mb-mar-right-0 title-color _flex-row-mid _mb-mar-bottom-20"><div class="iconfont icon-gantanhaozhong _b _pc-mar-right-5 _pc-font-23 _mb-mar-right-5 "></div> 以下预估运费仅做参考，详情请咨询客服</div>

          <div>寄运信息：{{route_data.info}}</div>
        </div>

        <div class="_b _pc-font-25 _mb-font-28 _text-center _w-max" v-if="route_data.list.length == 0">目前没有符合的线路</div>
        <div class="price-box">
          <!-- 遍历 -->
          <div class="grey-bg _pc-pad-x-30 _pc-pad-y-30 _mb-pad-y-30 _mb-pad-x-30 _mb-mar-bottom-25" style="border-radius: 8px;" v-for="(item,index) in route_data.list" :key="index">
            <div class="_mb-flex-row-bet-mid _mb-mar-bottom-30">
              <div class="_pc-font-28 _black  _mb-font-32 _pc-mar-bottom-15 _mb-mar-bottom-0 _mb-w-400 _line-1">{{item.express_name}}</div>
              <div class="title-color _pc-font-16 _mb-font-24 _pc-mar-bottom-25 _mb-mar-bottom-0 _b"> ￥ <span class="_pc-font-32 _mb-font-36">{{item.price}}</span> 运费 </div>
            </div>
            <div class="_flex-row-bet-mid _pc-font-16 _mb-font-24 _pc-mar-bottom-10 _mb-mar-bottom-30">
              <div>首重： <span class="_grey-99">¥{{item.start_fee}}/{{item.start_weight}}g</span></div>
              <div>续重：<span class="_grey-99">¥{{item.pre_weight_fee}}/{{item.init_weight}}g</span></div>
            </div>
            <div class="_pc-font-16 _mb-font-24 _pc-mar-bottom-10 _mb-mar-bottom-30" style="line-height:1.4;">线路特点： <span class="_grey-99">{{item.express_style}}</span></div>
            <div class="_pc-font-16 _mb-font-24 _pc-mar-bottom-10 _mb-mar-bottom-30" style="line-height:1.4;">物品限制： <span class="_grey-99">{{item.express_articles_limit}}</span></div>
            <div class="_pc-font-16 _mb-font-24 _pc-mar-bottom-10 _mb-mar-bottom-30" style="line-height:1.4;">长度限制： <span class="_grey-99">{{item.express_lenght_limit}}</span></div>
            <div class="_pc-font-16 _mb-font-24" style="line-height:1.4;">重量限制： <span class="_grey-99">{{item.express_weight_limit}}kg以内</span></div>
          </div>
          
        </div>
      </div>

    </div>

    <!-- pc中间图 无链接-->
    <img v-if="!price_img_a.url" class="_w-max wow fadeInLeft _mb-display-none" :src="price_img_a.image_input" alt="">
    <!-- pc中间图 有链接 -->
    <a class="_mb-display-none" v-if="price_img_a.url" :href="price_img_a.url" target="_blank"><img class="_w-max wow fadeInUp" :src="price_img_a.image_input" alt=""></a>

    <!-- 手机中间图 无链接-->
    <img v-if="!price_Phoneimg_a.url" class="_w-max wow fadeInLeft _pc-display-none" :src="price_Phoneimg_a.image_input" alt="">
    <!-- 手机中间图 有链接 -->
    <a class="_pc-display-none" v-if="price_Phoneimg_a.url" :href="price_Phoneimg_a.url" target="_blank"><img class="_w-max wow fadeInUp" :src="price_Phoneimg_a.image_input" alt=""></a>


    <div class="_pc-pad-y-60  _pc-pad-x-260  _mb-pad-y-60 _mb-pad-x-30 ">
      <div class="_flex-row-bet _pc-mar-bottom-45 _mb-mar-bottom-25 wow fadeInLeft">
        <div class="_flex-row">
          <div class="_pc-font-42 _mb-font-52 _b _pc-mar-right-20 title-color" style="line-height:0.9;">PROCESS</div>
          <div class="_pc-font-28 _mb-font-36 _b" style="align-self:flex-end;">下单流程</div>
        </div>
        <div class="_pc-font-16 _grey-99 _mb-display-none" style="align-self:flex-end">O N E &nbsp;&nbsp; S T O P &nbsp;&nbsp; S E R V I C E</div>
      </div>

      <div class="process-box wow fadeInRight">
        <div class="process-bg background-max _mb-flex-row" v-for="(item,index) in list" :key="index">
          <div class="_pc-pad-left-70 _pc-pad-right-50 _pc-pad-y-35 _mb-pad-y-35 _mb-pad-left-50 _mb-pad-right-40 _mb-flex-row-center-mid">
            <div>
              <div class="_flex-row-mid _pc-mar-bottom-20 _mb-mar-bottom-5">
              <div class="theme-bg _white  _pc-font-17 _pc-mar-right-10 _pc-pad-x-5 _mb-pad-x-5 _mb-mar-right-10 _mb-font-22" style="border-radius:13px; padding-bottom:3px;padding-top:3px;">0{{index+1}}</div>
              <div class="_black _pc-font-22 _mb-font-26">{{item.title}}</div>
            </div>
            <div class="_grey-99 _pc-font-15 _mb-font-22" style="line-height:1.4;" v-html="item.content"></div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'PcWebIndex',

  data() {
    return {
      isShow_sele:false,
      country_list:[],//国家列表
      country:{},//国家
      input_list:[],//获取的国家列表
      input_keywords:'',//国家关键词
      data:{
        height:null,
        length:null,
        width:null,
        area_id:null,
        weight:null,
      },
      checked:true,
      price_img_a:{},//物流轨迹查询链接以及图片
      price_Phoneimg_a:{},
      route_data:{},//路线
      list:[],//下单流程

      routine:{},//小程序
      account:{},//公众号
      
    };
  },

  mounted() {
    this.get_country();
    this.get_process();
    this.get_routine();
    this.price_img();
    setTimeout(()=>{
      new this.$wow.WOW().init()
    },20)
  },

  watch:{
    
  },

  methods: {

    delaycloseSele(){ //关闭多选框
      this.isShow_sele = false;
      this.$refs.inputs.blur();
    },

    fuzzyQuery(list, keyWord) {
      this.data.area_id = 0;
      var arr = [];
      for (var i = 0; i < list.length; i++) {
        if (list[i].name.indexOf(keyWord) >= 0) {
          arr.push(list[i]);
        }
      }
      if(keyWord == ''){
        arr = list;
      }

      if(arr.length != 0 &&keyWord === arr[0].name){
        this.data.area_id = arr[0].id;
      }


      return arr;
    },
      
    async get_country(){  //获取国家
      let that = this;
      await that.$axios.get('get_country',{}).then(res=>{
        that.country_list = res.data.data;
      })
    },

    changeSele(data){ //选取国家
      this.country = data;
      this.data.area_id = data.id;
      this.input_keywords = data.name;
      this.isShow_sele = false;
    },

    async price_img(){ //获取物流轨迹查询链接以及图片
      let that = this;
      await that.$axios.get('site/get_site_newList?cid=10&page=1&limit=2').then(res=>{
        that.price_Phoneimg_a = res.data.data.all[0];
        that.price_img_a = res.data.data.all[1];
      })
    },

    // 下单流程
    async get_process(){
      let that = this;
      await that.$axios.get('site/get_site_newList?cid=5&page=1&limit=15').then(res=>{
        that.list = res.data.data.all;
      })
    },

    async get_routine(){ //获取小程序/公众号
      let that = this;
      await that.$axios.get('site/get_site_newList?cid=6&page=1&limit=15').then(res=>{
        that.routine = res.data.data.all[0];
        that.account = res.data.data.all[1];
      })
    },

    

    async save(){ //查询
      let that = this;
      if(that.checked){that.checked = !that.checked;}else{return};
      // if(that.data.height){}else{alert('请填写包裹高度');that.checked =true; return};
      if(that.data.weight){}else{alert('请填写包裹重量');that.checked =true;return};
      // if(that.data.width){}else{alert('请填写包裹宽度');that.checked =true;return};
      // if(that.data.length){}else{alert('请填写包裹长度');that.checked =true;return};
      if(that.data.area_id){}else{alert('请选择地区');that.checked =true;return};
      let data = that.data;
      await that.$axios.post('predict_price_list',data).then(res=>{
        
        if(res.data.status ==200){
          that.route_data = res.data.data;
        }else{
          alert(res.data.msg);
        }

        that.checked = true;
      }).catch((res)=>{
        that.checked = true;
      })

    },
  },
};
</script>

<style scoped>
.title-color{color: var(--theme-color-title);}
.theme-bg{background: var(--theme-color-bg);}
.grey-bg{background: #F7F7F7;}

.price-box{
  display: grid;
  grid-template-columns: 22.65625vw 22.65625vw 22.65625vw;
  column-gap: 2.08333vw;
  row-gap: 1.04167vw;
}

.process-box{
  display: grid;
  grid-template-columns: 23.4375vw 23.4375vw 23.4375vw;
  column-gap: 1.04167vw;
  row-gap: 2.08333vw;
}

.background-max{
  background-size: 100% 100%;
  background-repeat:no-repeat;
}
.process-bg{
  background-image: url('../../assets/image/price-pro.png');
}

._btn-hover:hover{opacity: 0.4;}
._max-h-200{max-height:200px;}
@media screen and (max-device-width: 768px) {
  ._max-h-200{max-height:17.2207vh;}

  .price-box{
    display: block;
  }

  .process-box{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:2.28311vh 1.33333vw;
  }
}
</style>